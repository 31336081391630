import React from "react";

const Education = ({ experience, education }) => {
  return (
    <section
      className="w-[60%] ml-[20%] flex flex-col md:flex-row justify-between mb-28 md:mb-44"
      id="experience"
    >
      <div data-aos="fade-right" className="text-white  md:w-[48%]">
        <h3 className="text-4xl text-white">Experiences</h3>
        <div className="flex flex-col gap-10 mt-12">
          {experience.map((item, index) => (
            <div key={index} className="flex flex-col gap-5">
              <div className="p-2 border-2 w-fit rounded-lg border-blue-400 text-sm font-bold">
                {item.startYear} -{" "}
                {item.endYear === 0 ? "Present" : item.endYear}
              </div>
              <div>
                {" "}
                <h4 className="text-2xl">{item.company}</h4>
                <span className="text-base text-blue-400"> {item.role} </span>
              </div>
              <p className="text-gray-500 text-base"> {item.desc} </p>
              <span className="w-full border border-gray-500 border-opacity-50"></span>
            </div>
          ))}
        </div>
      </div>
      <div data-aos="fade-left" className="text-white md:w-[48%]">
        <h3 className="text-4xl text-white">Education</h3>
        <div className="flex flex-col gap-10 mt-12">
          {education.map((item, index) => (
            <div key={index} className="flex flex-col gap-5">
              <div className="py-2 px-4 border-2 w-fit rounded-lg border-blue-400 text-sm font-bold">
                {item.startYear} -{" "}
                {item.endYear === 0 ? "Present" : item.endYear}
              </div>
              <div>
                {" "}
                <h4 className="text-2xl">{item.institute}</h4>
                <span className="text-base text-blue-400"> {item.course} </span>
              </div>
              <p className="text-gray-500 text-base"> {item.desc} </p>
              <span className="w-full border border-gray-500 border-opacity-50"></span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Education;
