import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import DefaultLayout from "./Layout";

const PrivateLayout = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to={"/login"} replace />;
  }

  return (
    <>
      <DefaultLayout>
        <Outlet />
      </DefaultLayout>
    </>
  );
};

export default PrivateLayout;
