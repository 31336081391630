import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../Config";
import { toast } from "react-toastify";

const SiteSettings = () => {
  const token = localStorage.getItem("token");
  const [data, setData] = useState({
    title: "",
    logo: null,
    favicon: null,
    metaDescription: "",
    metaKeyword: "",
    template:"template-1",
    analyticsKey:"",
  });

  const fetchData = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/settings`);

      setData(res.data);
      // console.log('settings',res.data)
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "logo" || name === "favicon") {
      const file = files[0];
      setData((prevdata) => ({
        ...prevdata,
        [name]: file,
      }));
    } else {
      setData((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
    
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("logo", data.logo);
      formData.append("favicon", data.favicon);
      formData.append("title", data.title);
      formData.append("metaDescription", data.metaDescription);
      formData.append("metaKeyword", data.metaKeyword);
      formData.append("template",data.template);
      formData.append("analyticsKey",data.analyticsKey);

      formData.forEach((value, key) => {
        if (value instanceof File) {
          console.log(`${key}:`, value.name, value.size, value.type);
        } else {
          console.log(`${key}:`, value);
        }
      });
      const res = await axios.put(`${config.BASE_URL}/settings`, formData, {
        headers: {
          "x-auth-token": `Bearer ${token}`,
        },
      });
      if (res.status === 200) toast.success("Site Settings Updated!");
      fetchData();
    } catch (e) {
      toast.error("Something Went Wrong");
      console.log(e.message);
    }
  };

  return (
    <div className=" w-full bg-white shadow-default mb-16">
      <div className="border-b border-stroke bg-black py-4 px-6 ">
        <h3 className="font-medium text-white  text-xl">Site Settings</h3>
      </div>

      <form action="#" className="flex flex-col items-end">
        <div className="p-6 flex w-full flex-wrap gap-5 justify-between">
          <div className="mb-4 w-[47%]">
            <label className="mb-2 block text-base font-bold text-gray-800  ">
              Title
            </label>
            <input
              type="text"
              onChange={handleChange}
              value={data.title}
              name="title"
              placeholder="Enter Title"
              className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
            />
          </div>

          <div className="mb-4 w-[47%]">
            <label className="mb-2 block text-base font-bold text-gray-800  ">
              Logo
            </label>
            <input
              type="file"
              name="logo"
              onChange={handleChange}
              className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
            />
          </div>
          <div className="mb-4 w-[47%]">
            <label className="mb-2 block text-base font-bold text-gray-800  ">
              FaveIcon
            </label>
            <input
              type="file"
              onChange={handleChange}
              name="favicon"
              className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
            />
          </div>

          <div className="mb-4 w-[47%]">
            <label className="mb-2 block text-base font-bold text-gray-800  ">
              Meta Description
            </label>
            <input
              type="text"
              name="metaDescription"
              value={data.metaDescription}
              onChange={handleChange}
              placeholder="Enter Facebook URL"
              className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
            />
          </div>
          <div className="mb-4 w-[47%]">
            <label className="mb-2 block text-base font-bold text-gray-800  ">
              MetaKeyword
            </label>
            <input
              type="text"
              onChange={handleChange}
              value={data.metaKeyword}
              name="metaKeyword"
              placeholder="Enter Your Mail-Id"
              className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
            />
          </div>
          <div className="mb-4 w-[47%]">
            <label className="mb-2 block text-base font-bold text-gray-800  ">
              Analytics Key
            </label>
            <input
              type="text"
              onChange={handleChange}
              value={data.analyticsKey}
              name="analyticsKey"
              placeholder="Enter Your Google Analytics Key"
              className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
            />
          </div>
          <div className="mb-4 w-[47%]">
            <label className="mb-2 block text-base font-bold text-gray-800  ">
              Template
            </label>
            <select
              type="text"
              onChange={handleChange}
              value={data.template}
              name="template"
              placeholder="Enter Your Mail-Id"
              className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
            >
              <option value={'template-1'}>Template-1</option>
              <option value={'template-2'}>Template-2</option>
            </select>
          </div>
        </div>
        <button
          type="button"
          onClick={handleUpload}
          className="flex w-fit h-fit mr-7 px-7 justify-center rounded bg-lime-400 text-base text-white p-3 font-medium hover:bg-lime-600"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default SiteSettings;
