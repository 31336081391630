import React, { useEffect, useState } from "react";
import Partical from "./Partical2";
import Hero from "./Hero";
import About from "./About";
import Services from "./Services";
import Portfolio from "./Portfolio";
import Education from "./Education";
import Blogs from "./Blogs";
import Contact from "./Contact";
import Footer from "./Footer";
import Navbar from "./Navbar";
import config from "../../../Config";
import axios from "axios";
import { ThreeCircles } from "react-loader-spinner";

const Home = ({logo}) => {
  const [templateData, setTemplateData] = useState(null);

  const fetchTemplate1 = async () => {
    try {
      const res = await axios.get(
        `${config.BASE_URL}/template1/66bc86540d05df2493711202`
      );
     
        setTemplateData(res.data);
      
    } catch (e) {
      console.log(e.message);
    }
  };
  useEffect(()=>{
    fetchTemplate1();

  },[])

  useEffect(() => {
    if (templateData) {
      document.body.style.backgroundColor = "black";
      document.body.style.backgroundImage = `url('${config.IMG_URL}/${templateData.hero.bgImage}')`;
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.backgroundSize = "100vw 100vh";
      document.body.style.backgroundAttachment = "fixed";
      document.body.style.backgroundPosition = "center";
      document.body.style.margin = "0"; // Optional: Remove default margin
      document.body.style.height = "100vh"; // Optional: Full viewport height
    }

    // Clean up by removing the background image when the component unmounts
    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.backgroundImage = "";
      document.body.style.backgroundRepeat = "";
      document.body.style.backgroundSize = "";
      document.body.style.backgroundAttachment = "";
      document.body.style.backgroundPosition = "";
      document.body.style.margin = "";
      document.body.style.height = "";
    };
  }, [templateData]);

  return (
    <>
      {templateData && (
        <>
          <Partical style={{ positon: "fixed", zIndex: "-1" }} />
          <Navbar  logo={logo}/>
          <Hero hero={templateData.hero} />
          <About about={templateData.about} />
          <Services services={templateData.services} />
          <Portfolio portfolio={templateData.portfolio} />
          <Education
            experience={templateData.experience}
            education={templateData.education}
          />
          <Blogs blogHead={templateData.blogs.blogHead} />
          <Contact />
          <Footer />{" "}
        </>
      )}
      {!templateData && (
        <div className="w-full h-screen flex items-center justify-center bg-black">
          <ThreeCircles
            visible={true}
            height="300"
            width="300"
            color="rgb(96 165 250)"
            ariaLabel="three-circles-loading"
          />
        </div>
      )}
    </>
  );
};

export default Home;
