import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../Config";
import { useNavigate } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

const BlogList = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [blogList, setBlogList] = useState([]);
  const fetchBlogs = async () => {
    const res = await axios.get(`${config.BASE_URL}/blogs`);
    setBlogList(res.data);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleEditClick = (id, data) => {
    // console.log(id);
    navigate(`/edit-blog/${id}`, { state: { data } });
  };
  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Thumbnail",
      selector: (row) => (
        <>
          <img
            src={`${config.IMG_URL}/${row.image}`}
            className="w-full h-[40px]  opacity-90  md:h-[70px]"
            alt="anything"
          />
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-5">
          <div
            onClick={() => handleDeleteClick(row._id)}
            className="cursor-pointer text-red-400 hover:text-red-600"
          >
            <MdDeleteForever size={"1.5rem"} />
          </div>
          <div
            onClick={() => handleEditClick(row._id, row)}
            className=" cursor-pointer text-blue-300 hover:text-blue-600"
          >
            <FaRegEdit size={"1.5rem"} />
          </div>
        </div>
      ),
    },
  ];

  const handleDeleteClick = async (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure you want to delete?");
      if (userConfirmed) {
        const res = await axios.delete(`${config.BASE_URL}/blogs/${id}`, {
          headers: { "x-auth-token": `Bearer ${token}` },
        });
        if (res.status === 200) {
          fetchBlogs();
          toast.success("Blog Deleted Succesfully!");
        }
      } else {
        console.log("Delete action canceled");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleCreate = () => {
    navigate("/create-blog");
  };

  return (
    <div className="p-5">
      <div className="flex justify-between">
        <h1>Blogs List</h1>
        <button
          type="button"
          onClick={handleCreate}
          className="flex w-fit px-5 justify-center gap-2 items-center rounded bg-lime-400 text-base text-white p-3 font-medium hover:bg-lime-600"
        >
          <FaPlus /> Create Blog
        </button>
      </div>
      <div className=" mt-10 md:mt-16 w-full ">
        {blogList && (
          <DataTable
            columns={columns}
            data={blogList}
            pagination
            highlightOnHover
          />
        )}
        {/* {blogList.map((item, index) => (
          <div
            key={index}
            className="flex flex-col relative hover:cursor-pointer overflow-clip  justify-around w-full md:w-[22%] max-h-[500px] bg-black bg-opacity-10 rounded-md backdrop-blur-sm shadow-sm shadow-gray-700"
          >
            <Link target="_blank" key={item._id} to={`/${item.slug}`}>
              <img
                src={`${config.IMG_URL}/${item.image}`}
                className="w-full h-[100px]  opacity-90  md:h-[150px]"
                alt="anything"
              />
              <div className="px-6 mt-2">
                <span className="text-blue-400 text-xs md:text-base">
                  {item.tag}{" "}
                </span>
                <h6 className="text-xl md:text-2xl  text-black">
                  {item.title}
                </h6>
              </div>
              <p className="text-xs md:text-base  text-slate-900 px-6 mb-3">
                {" "}
                {item.description}{" "}
              </p>
            </Link>
            <div
              onClick={() => handleDeleteClick(item._id)}
              className="absolute top-3 right-3 cursor-pointer text-red-400 hover:text-red-600"
            >
              <MdDeleteForever size={"1.5rem"} />
            </div>
            <div
              onClick={() => handleEditClick(item._id, item)}
              className="absolute top-3 right-12 cursor-pointer text-blue-300 hover:text-blue-600"
            >
              <FaRegEdit size={"1.5rem"} />
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default BlogList;
