import React, { useState } from "react";
import Template1Update from "../Template1/Edit/Template1Update";
import Template2Update from "../Template2/Edit/Template2Update";

const UpdateLandingPage = () => {

  const [temp,setTemp] = useState(1);

  return (
    <>
      <div className="w-full flex text-white top-0 p-4 gap-5 bg-black">
        <p className={`cursor-pointer ${temp === 1 ? 'text-blue-400' : ""}`} onClick={() => setTemp(1)}>Template-1</p>{" "}
        <p className={`cursor-pointer ${temp === 2 ? 'text-[#25FF00]' : ""}`} onClick={() => setTemp(2)}>Template-2</p>
      </div>
      {temp === 1 ? <Template1Update /> : <Template2Update/> }
      
    </>
  );
};

export default UpdateLandingPage;
