import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../Config";
import axios from "axios";
import { MdDoubleArrow } from "react-icons/md";
import slugify from "react-slugify";

const BlogList = () => {
  const [blogList, setBlogList] = useState([]);
  const fetchBlogs = async () => {
    const res = await axios.get(`${config.BASE_URL}/blogs`);
    setBlogList(res.data);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <section
      className="w-[80%] ml-[10%] md:w-[60%] md:ml-[20%] mb-44"
      id="blogs"
    >
      <h1 className="text-3xl md:text-5xl text-[#25FF00] text-center">Blogs</h1>

      {blogList && (
        <div className="flex w-full flex-wrap gap-10 justify-between mt-32">
          {blogList.slice(0,5).map((item, index) => (
            <div
              key={index}
              className="flex flex-col hover:cursor-pointer overflow-clip  justify-between w-full md:w-[30%] bg-black bg-opacity-10 border border-green-900 rounded-md backdrop-blur-sm hover:shadow-md hover:shadow-[#25FF00]"
            >
              <Link
                target="_blank"
                key={item._id}
                to={`/${item.slug}`}
              >
                <img
                  src={`${config.IMG_URL}/${item.image}`}
                  className="w-full h-[150px] md:h-[220px]"
                  alt="anything"
                />
                <div className="px-6 mt-2">
                  <span className="text-green-400 text-sm md:text-base">
                    {item.date.substring(0,10)}{" "}
                  </span>
                  <h6 className="text-xl md:text-3xl text-white">
                    {item.title}
                  </h6>
                </div>
                <p className="text-sm md:text-base text-slate-300 px-6 mb-3">
                  {" "}
                  {item.description}{" "}
                </p>
              </Link>
            </div>
          ))}
           <div
              className="flex flex-col hover:cursor-pointer overflow-clip items-center justify-center w-full md:w-[30%] bg-black bg-opacity-10 border border-green-900 rounded-md backdrop-blur-sm hover:shadow-md hover:shadow-[#25FF00]"
            >
              <Link
                target="_blank"
                to={`/blogs`}
              >
                
                <div className="px-6 mt-2 flex flex-col items-center">
                <MdDoubleArrow className="text-[#25ff00]" size={'3rem'} />
                  <h6 className="text-xl md:text-3xl text-[#25ff00]">
                    View All Blogs
                  </h6>
                </div>
              
              </Link>
            </div>
        </div>
      )}
    </section>
  );
};

export default BlogList;
