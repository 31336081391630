import React from "react";
import { MdAdd, MdDeleteForever } from "react-icons/md";

const UpdateExperience = ({ xpData, handleChange, addXp , deleteExperience}) => {
  // const [xpData, setXpData] = useState([
  //   {
  //     title: "Software Developer 2",
  //     desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.",
  //     start: "2022",
  //     end: "present",
  //   },
  //   {
  //     title: "Software Developer 2",
  //     desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.",
  //     start: "2021",
  //     end: "2022",
  //   },
  //   {
  //     title: "Software Developer 2",
  //     desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.",
  //     start: "2020",
  //     end: "2021",
  //   },
  //   {
  //     title: "Software Developer-1 at  Microsoft",
  //     desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.",
  //     start: "2018",
  //     end: "2020",
  //   },
  // ]);

  // const handleChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedXp = xpData.map((item, idx) =>
  //     idx === index ? { ...item, [name]: value } : item
  //   );
  //   setXpData(updatedXp);
  // };

  // const addXp = () => {
  //   setXpData((prevData) => [

  //     {
  //       title: "",
  //       desc: "",
  //       start: "",
  //       end: "",
  //     },
  //     ...prevData,
  //   ]);
  // };

  return (
    <div className="flex w-full bg-white">
      <div
        className="flex w-2/3 bg-gray-800 flex-col items-center justify-center gap-5"
        id="services"
      >
        <h3 className="text-2xl md:text-4xl text-center text-white">
          Experience
        </h3>
        {xpData && (
          <div className=" flex flex-row flex-wrap  items-center  justify-around gap-5  text-white p-10">
            {xpData.map((item, index) => (
              <div key={index} className="flex w-[47%] flex-col gap-5">
                <div className="p-2 border-2 w-fit rounded-lg border-blue-400 text-sm font-bold">
                  {item.startYear} -{" "}
                  {item.endYear === 0 || item.endYear === '0' ? "Present" : item.endYear}
                </div>
                <div>
                  {" "}
                  <h4 className="text-2xl">{item.company}</h4>
                  <span className="text-base text-blue-400"> {item.role} </span>
                </div>
                <p className="text-gray-500 text-base"> {item.desc} </p>
                <span className="w-full border border-gray-500 border-opacity-50"></span>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className=" w-1/3 h-auto bg-white shadow-default ">
        <div className="border-b bg-black border-stroke py-4 px-6 ">
          <h3 className="font-medium  text-xl text-white">Section-5</h3>
        </div>
        <form action="#">
          <div className="p-6">
            {xpData.map((item, index) => (
              <div key={index} className="p-2 pt-2 relative border rounded-lg mb-2">
                {/* <h6>{`Experience-${index + 1}`}</h6> */}
                <div className="mb-1 flex gap-2">
                  <div className="w-1/2">
                    <label className="mb-2 block text-base font-bold text-gray-800  ">
                      Company
                    </label>
                    <input
                      type="text"
                      onChange={(e) => handleChange(e, index)}
                      value={item.company}
                      name="company"
                      placeholder="Enter Company Name"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="mb-2 block text-base font-bold text-gray-800  ">
                      Role
                    </label>
                    <input
                      type="text"
                      onChange={(e) => handleChange(e, index)}
                      value={item.role}
                      name="role"
                      placeholder="Enter Role"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
                    />
                  </div>
                  
                </div>

                <div className="flex gap-2">
                <div className="w-1/2">
                    <label className="mb-2 block text-base font-bold text-gray-800  ">
                      Starting Year
                    </label>
                    <input
                      type="number"
                      value={item.startYear}
                      onChange={(e) => handleChange(e, index)}
                      name="startYear"
                      placeholder="Enter Starting Year"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="mb-2 block text-base font-bold text-gray-800  ">
                      End Year (0 for present)
                    </label>
                    <input
                      type="number"
                      onChange={(e) => handleChange(e, index)}
                      value={item.endYear}
                      name="endYear"
                      placeholder="Enter Completion Year"
                      className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="mb-2 block text-base font-bold text-gray-800  ">
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="desc"
                    value={item.desc}
                    onChange={(e) => handleChange(e, index)}
                    placeholder="Enter Description"
                    className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
                  />
                </div>
                <div className="absolute top-1 right-4 cursor-pointer" onClick={()=>deleteExperience(index)}>
                  <MdDeleteForever  className='text-red-700 text-xl'/>
                  </div>
              </div>
            ))}
            <div className="w-full flex justify-end">
              <button
                type="button"
                onClick={addXp}
                className="flex w-fit gap-2 items-center mt-3 mb-2 justify-center rounded bg-blue-400 text-base text-white p-3 font-medium hover:bg-blue-600"
              >
                <MdAdd size={"1.7rem"} />
                Add Experience
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateExperience;
