import axios from "axios";
import React, { useState } from "react";
import config from "../../../Config";
import { toast } from "react-toastify";

const ContactMe = () => {
  const [data, setData] = useState({
    name: "",
    mail: "",
    phone: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post(`${config.BASE_URL}/contact`, data);
      if (res.status === 201) {
        toast.success("Your Message has been sent! Thankyou");
        setData({
          name: "",
          mail: "",
          phone: "",
          description: "",
        });
      }
    } catch (error) {
      console.log(error);
      if(error.status === 405)
        toast.error("There is already a message associated with your email.");
    }
  };
  return (
    <section
      className="w-[60%] ml-[20%] mb-44 text-white flex  flex-col items-center"
      id="contact"
    >
      <h1 className="text-3xl md:text-5xl text-[#25FF00] text-center">
        Contact Me
      </h1>
      <div className="flex mt-20 w-full flex-col sm:flex-row justify-center gap-16 md:gap-32">
        <div className="flex flex-col w-full h-full md:w-1/3 gap-5 md:gap-8 justify-between">
          <input
            className="bg-transparent backdrop-blur-sm border-2 border-gray-500 focus:outline-none focus:border-[#25FF00]  rounded-md w-full h-12 p-4"
            type="text"
            name="name"
            value={data.name}
            placeholder="Your Name"
            onChange={handleChange}
          />
          <input
            className="bg-transparent backdrop-blur-sm border-2 border-gray-500 focus:outline-none focus:border-[#25FF00] rounded-md w-full h-12 p-4"
            type="email"
            name="mail"
            placeholder="Your Email"
            onChange={handleChange}
            value={data.mail}
          />
          <input
            className="bg-transparent backdrop-blur-sm border-2 border-gray-500 focus:outline-none focus:border-[#25FF00] rounded-md w-full h-12 p-4"
            type="tel"
            name="phone"
            placeholder="Your Phone Number"
            onChange={handleChange}
            value={data.phone}
          />
        </div>
        <div className="w-full md:w-1/3">
          <textarea
            className="bg-transparent backdrop-blur-sm border-2 border-gray-500 focus:outline-none focus:border-[#25FF00] rounded-md w-full h-44 md:h-full p-4"
            placeholder="Your Message"
            onChange={handleChange}
            name="description"
            value={data.description}
          />
        </div>
      </div>
      <button
      type="button"
      onClick={handleSubmit}
       className="bg-transparent backdrop-blur-sm mt-14 md:mt-28 hover:bg-[#25FF00] transition-all duration-300 text-slate-300 font-semibold text-lg hover:text-white  py-2 px-4 md:px-8  border border-slate-500 hover:border-transparent rounded">
        Submit
      </button>
    </section>
  );
};

export default ContactMe;
