import React from "react";
import { Link } from "react-router-dom";


import { Typewriter } from "react-simple-typewriter";

const Hero = ({ hero }) => {
  return (
    <section
      className="h-screen w-[60%] ml-[20%] flex flex-row  justify-start items-center text-white"
      id="home"
    >
      <div className="md:w-2/3  flex flex-col justify-center ">
        <span
          data-aos="fade-right"
          className="text-white text-sm md:text-base mb-6"
        >
          Introduction
        </span>
        <h1 data-aos="fade-right" className="text-[3.2rem] md:text-6xl mb-16">
          {hero.head}{" "}
          <span className="text-blue-400">
            {" "}
            <Typewriter
              loop={0}
              cursor={true}
              deleteSpeed={120}
              typeSpeed={150}
              words={hero.roles}
            />{" "}
          </span>{" "}
        </h1>
        <Link target="_blank" to={hero.buttonLink}>
          <button
            data-aos="fade-right"
            className="bg-transparent hover:bg-blue-400 text-slate-300 font-semibold text-lg hover:text-white md:w-1/4 py-2 px-4 border border-slate-500 hover:border-transparent rounded"
          >
            {hero.buttonText}
          </button>
        </Link>
      </div>
    </section>
  );
};

export default Hero;
