import React from "react";
import { Link } from "react-router-dom";
import config from "../../../Config";

const Portfolio = ({ portfolio }) => {
  return (
    <section
      className="w-[60%] ml-[20%] flex flex-col items-center mb-28 md:mb-44"
      id="portfolio"
    >
      <span
        data-aos="fade-up"
        className="text-blue-400 mb-1 text-sm md:text-base"
      >
        Portfolio
      </span>
      <h3
        data-aos="fade-up"
        className="text-2xl md:text-4xl text-center text-white"
      >
        {portfolio.portfolioHead}
      </h3>

      <div className="flex flex-wrap mt-20 justify-around w-[100%]   h-[50vh] gap-2">
        {portfolio.projects.map((item, index) => (
          <Link
            data-aos={
              (index + 1) % 3 === 0
                ? "fade-left"
                : (index + 1) % 3 === 1
                ? "fade-right"
                : "fade-down"
            }
            to={item.projectLink}
            target="_blank"
            key={index}
            className="w-[48%] h-[30%]  md:w-[32.7%] md:h-[49%] overflow-hidden bg-gradient-to-tr cursor-pointer relative from-[#1e88e9] to-[#72d5f4]"
          >
            <p className="text-xl text-white absolute top-[45%] right-[40%] object-cover  z-[0]">
              {item.projectName}
            </p>
            <img
              src={`${config.IMG_URL}/${item.img}`}
              alt="something"
              className="hover:opacity-0  w-full h-full relative  z-10 "
            />
          </Link>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
