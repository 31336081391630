import React from "react";
import { Typewriter } from "react-simple-typewriter";
import config from "../../../Config";
import { Link } from "react-router-dom";

const Hero = ({ hero }) => {
  
  

  return (
    <section
      className="h-screen  w-[60%] ml-[20%] md:w-full md:ml-[20%] flex flex-row items-center justify-start text-white"
      id="hero"
    >
      <div className="md:w-1/3 md:h-[40%] flex flex-col justify-center">
        <h1 className="text-[3.2rem] md:text-6xl mb-16">
          {hero.head}{" "}
          <span className="text-[#25FF00] ">
            {" "}
            <Typewriter
              loop={0}
              cursor={true}
              deleteSpeed={120}
              typeSpeed={150}
              words={hero.roles}
            />{" "}
          </span>{" "}
        </h1>
        <Link to={hero.buttonLink} target="_blank">
        <button className="bg-transparent hover:bg-[#25FF00] transition-all duration-300  text-slate-300 font-semibold text-lg hover:text-white md:w-fit py-2 px-4 border border-white hover:border-transparent rounded">
          {hero.buttonText}
        </button>
        </Link>
      </div>
      <div className="hidden md:block w-[40%]">
        <img src={`${config.IMG_URL}/${hero.heroImg}`} alt="hero-image" className="opacity-75"></img>
      </div>
    </section>
  );
};

export default Hero;
