import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import config from "../../Config";

const Login = () => {
  const navigate = useNavigate();
  const [passShow, setPassShow] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleLogin = async () => {
    try {
      const res = await axios.post(`${config.BASE_URL}/auth/login`, data);
      if (res.status === 200) {
        localStorage.setItem("token", res.data.token);
        navigate("/dashboard");
      } else {
        setData((prevData) => ({
          ...prevData,
          email: "",
          password: "",
        }));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="h-screen w-full flex justify-center items-center bg-gradient-to-tr from-blue-800 via-blue-500  to-blue-400 text-white ">
      <div className="border w-1/4 rounded-lg bg-black backdrop-blur-sm bg-opacity-5 border-white p-6">
        <form>
          <div className="flex flex-col my-9 gap-5">
            <label className=" text-3xl">Email</label>
            <input
              type="email"
              name="email"
              value={data.email}
              onChange={handleChange}
              className="rounded-md p-3 text-xl h-12 bg-transparent border border-white "
            />
          </div>
          <div className="flex flex-col my-9 gap-5  relative">
            <label className=" text-3xl"> Password</label>
            <input
              type={passShow ? "text" : "password"}
              name="password"
              value={data.password}
              onChange={handleChange}
              className="rounded-md p-3 text-xl h-12 bg-transparent border border-white "
            />
            <div
              className="absolute top-[4.3rem] cursor-pointer text-2xl right-4"
              onClick={() => setPassShow(!passShow)}
            >
              {passShow ? <FaRegEye /> : <FaRegEyeSlash />}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <Link>
              <p>Forgot password?</p>
            </Link>
            <button
              type="button"
              onClick={handleLogin}
              className="w-fit px-7 text-xl py-2 border rounded-md border-white bg-transparent hover:text-blue-600 hover:bg-white"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
