import React from "react";


const Work = ({projects}) => {
  

  const getclassname = (index) =>{
    if(index === 0){
      return "md:row-span-3"
    }
    if(index === 1){
      return "md:col-span-1";
    }
    if(index === 2){
      return "md:row-span-2";
    }
    if(index === 3){
      return "md:col-span-1";
    }
    if(index === 4){
      return "md: col-span-2";
    }
  }


  return (
    <section className="w-[80%] ml-[10%] md:w-[60%] md:ml-[20%] mb-44" id="projects">
      <h1 className="text-3xl md:text-5xl text-[#25FF00] text-center">
        Projects I have Done!
      </h1>
      <div className=" mt-28 mb-44 ">


        <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-1 md:grid-row-4 gap-4">
          {projects.map((item, index) => (
            <div
              key={index}
              className={`${getclassname(index)}  bg-black border border-gray-700 bg-opacity-10 rounded-md backdrop-blur-sm cursor-pointer hover:shadow-md transition-all duration-300 hover:shadow-[#25FF00]`}
            >
              <div className="flex flex-col h-full justify-around p-4">
                <div>
                  <h6 className="text-3xl text-green-400">{item.projectName}</h6>
                  
                </div>
                
                <p className="text-base text-slate-300"> {item.projectDesc} </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Work;
