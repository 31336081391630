import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Work from "./Work";
import Experience from "./Experience";
import ContactMe from "./ContactMe";
import Footer from "./Footer";
import About from "./About";
import BlogList from "./BlogList";
import { MatrixRainingLetters } from "react-mdr";
import axios from "axios";
import config from "../../../Config";
import { ThreeCircles } from "react-loader-spinner";

const Home2 = () => {
  const [templateData, setTemplateData] = useState(null);
  const fetchTemplate2 = async () => {
    try {
      const res = await axios.get(
        `${config.BASE_URL}/template2/66b059a0e4268d5041e2f00d`
      );

      setTemplateData(res.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchTemplate2();
    // Set the background image
    document.body.style.backgroundColor = "black";
    document.body.style.backgroundImage = "none";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundPosition = "center";
    document.body.style.margin = "0"; // Optional: Remove default margin
    document.body.style.height = "100vh"; // Optional: Full viewport height

    // Clean up by removing the background image when the component unmounts
    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.backgroundSize = "";
      document.body.style.backgroundPosition = "";
      document.body.style.margin = "";
      document.body.style.height = "";
    };
  }, []);

  return (
    <>
      
        {templateData && (
          <>
            <MatrixRainingLetters
              key="foo-bar"
              custom_class="m-0 p-0 fixed z-[-1] opacity-80"
            />
            <Navbar />
            <Hero hero={templateData.hero} />
            <About about={templateData.about} />
            <Experience experience={templateData.experience} />
            <Work projects={templateData.projects} />
            <BlogList />
            <ContactMe />
            <Footer />
          </>
        )}
     
      {!templateData && (
        <div className="w-full h-screen flex items-center justify-center bg-black">
          <ThreeCircles
            visible={true}
            height="300"
            width="300"
            color="#25ff00"
            ariaLabel="three-circles-loading"
          />
        </div>
      )}
    </>
  );
};

export default Home2;
