import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../Config";
import slugify from "react-slugify";

const AllBlogs = () => {
  const [blogList, setBlogList] = useState([]);
  const fetchBlogs = async () => {
    const res = await axios.get(`${config.BASE_URL}/blogs`);
    setBlogList(res.data);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);
  return (
    <div className=" bg-gradient-to-br from-blue-50 to-gray-100 min-h-screen py-11 px-40">
      <h1 className="text-gray-900">All Blogs</h1>
      <hr className="w-full mt-4 border-black"></hr>
      <div className="flex flex-wrap gap-5 mt-14 justify-between w-full">
        {blogList.slice(0, 5).map((item, index) => (
          <div
            key={index}
            className="flex flex-col hover:cursor-pointer overflow-clip  justify-between w-full md:max-w-[25%] max-h-[500px] bg-white rounded-md backdrop-blur-sm shadow-xl shadow-gray-500"
          >
            <Link target="_blank" key={item._id} to={`/${item.slug}`}>
              <img
                src={`${config.IMG_URL}/${item.image}`}
                className="w-full h-[130px] md:h-[170px]"
                alt="blog-image"
              />
              <div className="px-6 mt-2">
                <span className="text-blue-400 text-xs md:text-base">
                  {item.date.substring(0, 10)}{" "}
                </span>
                <h6 className="text-xl md:text-3xl  text-black">
                  {item.title}
                </h6>
              </div>
              <p className="text-xs md:text-base  text-gray-600 px-6 mb-3">
                {" "}
                {item.description}{" "}
              </p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllBlogs;
