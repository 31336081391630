import React from "react";





const getclassname = (index) =>{
  if(index === 0){
    return "md:row-span-2";
  }
  if(index === 1){
    return "md: col-span-2";
  }
  if(index === 2){
    return "md:col-span-1";
  }
  if(index === 3){
    return "md:row-span-1";
  }
}

const Experience = ({experience}) => {


  return (
    <section className="w-[80%] ml-[10%] md:w-[60%] md:ml-[20%] mb-44" id="experience">
      <h1 className="text-3xl md:text-5xl text-[#25FF00]  text-center">
        Experience
      </h1>
      <div className=" mt-32">        
        <div className="grid md:grid-cols-3 md:grid-row-4 gap-4">
          {experience.map((item,index)=>(
            <div key={index} className={` ${getclassname(index)}  bg-black border border-gray-700 bg-opacity-10 rounded-md backdrop-blur-sm cursor-pointer hover:shadow-md transition-color duration-300 hover:shadow-[#25FF00]`}>
              <div className="flex flex-col h-full justify-around p-4">
              <div>
                    <h6 className="text-3xl text-green-400">{item.company}</h6>
                    <span className="text-slate-300 text-xs">{item.startYear} - {item.endYear === 0 || item.endYear === '0' ? 'Present' : item.endYear} </span>
                  </div>
                  <p className="text-slate-100 text-xl"> {item.role} </p>
                  <p className="text-base text-slate-300"> {item.desc} </p>
                
              </div>

            </div>
          ))}
        </div>

      </div>
    </section>
  );
};

export default Experience;
