import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import config from "../../Config";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";

const ContactInfo = () => {
  const [data, setData] = useState(null);
  const token = localStorage.getItem('token');

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Mail",
      selector: (row) => row.mail,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.description,
    },
    {
      name: "Action",
      selector: (row) => (
          <div
            onClick={() => handleDeleteClick(row._id)}
            className="cursor-pointer text-red-400 hover:text-red-600"
          >
            <MdDeleteForever size={"1.5rem"} />
          </div>
      ),
    },
  ];

  const handleDeleteClick = async (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure you want to delete?");
      if (userConfirmed) {
        const res = await axios.delete(`${config.BASE_URL}/contact/${id}`, {
          headers: { "x-auth-token": `Bearer ${token}` },
        });
        if (res.status === 200) {
          fetchData();
          toast.success("Contact Deleted Succesfully!");
        }
      } else {
        console.log("Delete action canceled");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/contact`, {
        headers: { "x-auth-token": `Bearer ${token}` },
      });
      setData(res.data.data);
      // console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-2">
      <h2 className="font-bold text-gray-900 m-5">Forms Submitted</h2>
      <div className="w-full p-4 ">
        {data && (
          <DataTable
            columns={columns}
            data={data}
            pagination
            highlightOnHover
          />
        )}
      </div>
    </div>
  );
};

export default ContactInfo;
