import React from "react";



const Dashboard = () => {


  return (
    <div className="p-2">
      <h2 className="font-bold text-gray-900 m-5">Dashboard</h2>
      <hr className="w-full border-gray-800"/>
      {/* <div className="flex w-full flex-wrap gap-3 justify-around mt-16">
        <div className="py-5 w-[20%]  rounded-lg bg-slate-100 shadow-lg flex flex-col items-center">
        <FaUserFriends size={'3rem'}  className="text-blue-300"/>
            <h4 className="text-gray-700"><b>User Count</b></h4>
            <p className="text-gray-500">0</p>
        </div>
        <div className="py-5  w-[20%]  rounded-lg bg-slate-100 shadow-lg flex flex-col items-center">
        <FaUserFriends size={'3rem'}  className="text-violet-300"/>
            <h4 className="text-gray-700"><b>Total Messages</b></h4>
            <p className="text-gray-500">0</p>
        </div>
        <div className="py-5  w-[20%]  rounded-lg bg-slate-100 shadow-lg flex flex-col items-center">
        <FaUserFriends size={'3rem'}  className="text-red-300"/>
            <h4 className="text-gray-700"><b>No. Templates</b></h4>
            <p className="text-gray-500">0</p>
        </div>
        <div className="py-5  w-[20%]  rounded-lg bg-slate-100 shadow-lg flex flex-col items-center">
        <FaUserFriends size={'3rem'}  className="text-lime-300"/>
            <h4 className="text-gray-700"><b>Blogs Count</b></h4>
            <p className="text-gray-500">0</p>
        </div>
      </div> */}
    <h4 className="text-gray-500 mt-20 ml-8">Please use the navigation links on the left to access different sections of the administration suite.</h4>

    </div>
  );
};

export default Dashboard;
