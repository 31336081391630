import axios from "axios";
import React, { useState } from "react";
import config from "../../../Config";
import { toast } from "react-toastify";

const Contact = () => {
  const [data, setData] = useState({
    name: "",
    mail: "",
    phone: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleSubmit = async() =>{
    try {
    const res = await axios.post(`${config.BASE_URL}/contact`,data);
    if(res.status === 201){
      toast.success("Your Message has been sent! Thankyou");
      setData({
        name: "",
        mail: "",
        phone: "",
        description: "",
      })
    }  
    } catch (error) {
      console.log(error);
      if(error.status === 405)
      toast.error("There is already a message associated with your email.");
    }
  }

  return (
    <section
      className="w-[60%] ml-[20%] flex flex-col items-center mb-28 md:mb-44"
      id="contact"
    >
      <span
        data-aos="fade-up"
        className="text-blue-400 mb-1 text-sm md:text-base"
      >
        Have Questions?
      </span>
      <h3 data-aos="fade-up" className="text-2xl md:text-4xl text-white">
        Contact Me Now
      </h3>

      <div className="flex mt-20 w-full flex-col sm:flex-row justify-center text-white gap-16 md:gap-32">
        <div
          data-aos="fade-right"
          className="flex flex-col w-full h-full md:w-1/3 gap-5 md:gap-8 justify-between"
        >
          <input
            className="bg-transparent backdrop-blur-sm border-2 border-gray-600 rounded-md w-full h-12 p-4"
            type="text"
            name="name"
            value={data.name}
            onChange={handleChange}
            placeholder="Your Name"
            required
          />
          <input
            className="bg-transparent backdrop-blur-sm border-2 border-gray-600 rounded-md w-full h-12 p-4"
            type="email"
            name="mail"
            value={data.mail}
            onChange={handleChange}
            placeholder="Your Email"
            required
          />
          <input
            className="bg-transparent backdrop-blur-sm border-2 border-gray-600 rounded-md w-full h-12 p-4"
            type="tel"
            name="phone"
            value={data.phone}
            onChange={handleChange}
            placeholder="Your Phone Number"
            required
          />
        </div>
        <div data-aos="fade-left" className="w-full md:w-1/3">
          <textarea
            className="bg-transparent backdrop-blur-sm border-2 border-gray-600 rounded-md w-full h-44 md:h-full p-4"
            placeholder="Your Message"
            value={data.description}
            onChange={handleChange}
            name="description"
            required
          />
        </div>
      </div>
      <button
      type="button"
      onClick={handleSubmit}
        data-aos="fade-down"
        className="bg-transparent backdrop-blur-sm mt-14 md:mt-28 hover:bg-blue-400 text-slate-300 font-semibold text-lg hover:text-white  py-2 px-4 md:px-8  border border-slate-500 hover:border-transparent rounded"
      >
        Submit
      </button>
    </section>
  );
};

export default Contact;
