import React from "react";
import { MdAdd, MdDeleteForever } from "react-icons/md";


const UpdateProjects = ({projectsData,handleChange,addNew,deleteProject}) => {
  // const [projectsData,setProjectsData] = useState({
  //   projectHead: "Projects I have done!",
  //   projects: [
  //     {
  //       title: "Project 1",
  //       desc: "awd ihfe akjshf skjhbfie shgdg kjghsdf jksdk bhsjkbf jkshdk bh kjhdckqa hb awd ihfe akjshf skjhbfie shgdg kjghsdf jksdk bhsjkbf jkshdk bh kjhdckqa hb",
  //       class: "md:row-span-3",
  //       projectLink:""
        
  //     },
  //     {
  //       title: "Project-2",
  //       desc: "awd ihfe akjshf skjhbfie shgdg kjghsdf jksdk bhsjkbf jkshdk bh kjhdckqa hb",
  //       class: "md:col-span-1",
  //       projectLink:"",
        
  //     },
  //     {
  //       title: "Project-3",
  //       desc: "awd ihfe akjshf skjhbfie shgdg kjghsdf jksdk bhsjkbf jkshdk bh kjhdckqa hb",
  //       class: "md:row-span-2",
  //       projectLink:"",
       
  //     },
  //     {
  //       title: "Project-4",
  //       desc: "awd ihfe akjshf skjhbfie shgdg kjghsdf jksdk bhsjkbf jkshdk bh kjhdckqa hb",
  //       class: "md:col-span-1",
  //       projectLink:"",
  //     }, 
  //     {
  //       title: "Project-5",
  //       desc: "awd ihfe akjshf skjhbfie shgdg kjghsdf jksdk bhsjkbf jkshdk bh kjhdckqa hb",
  //       class: "md:col-span-2",
  //       projectLink:"https://",
  //     },
      
  //   ],
  // });


  // const handleChange = (e, index) => {
  //   const { name, value, files } = e.target;

  //  if (name === "projectHead") {
  //     setProjectsData((prevdata) => ({
  //       ...prevdata,
  //       [name]: value,
  //     }));
  //   } else if (name === "title" || name === "desc" || name === "projectLink") {
  //     const updatedProject = [...projectsData.projects];
  //     updatedProject[index] = {
  //       ...updatedProject[index],
  //       [name]: value,
  //     };
  //     setProjectsData((prevdata) => ({
  //       ...prevdata,
  //       projects:updatedProject,
  //     }));
  //   }
  // };
  // const addNewProject = () => {
  //   setProjectsData((prevdata) => ({
  //     ...prevdata,
  //     projects: [
  //       ...prevdata.projects,
  //       { title: "", projectLink: "",desc: "" }, // Adding a new skill entry with empty values
  //     ],
  //   }));
  // };
  const getclassname = (index) =>{
    if(index === 0){
      return "md:row-span-3"
    }
    if(index === 1){
      return "md:col-span-1";
    }
    if(index === 2){
      return "md:row-span-2";
    }
    if(index === 3){
      return "md:col-span-1";
    }
    if(index === 4){
      return "md: col-span-2";
    }
  }


  return (
    <div className="flex">
      <div
        className="flex w-2/3 p-6 bg-gray-800 flex-col items-center justify-center"
        id="services"
      >
        <h1 className="text-3xl md:text-5xl text-[#25FF00] text-center">
        Projects!
      </h1>
      <div className=" mt-28 mb-44 ">


        <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-1 md:grid-row-4 gap-4">
          {projectsData.map((item, index) => (
            <div
              key={index}
              className={`${getclassname(index)}  bg-black border border-gray-700 bg-opacity-10 rounded-md backdrop-blur-sm cursor-pointer hover:shadow-md transition-all duration-300 hover:shadow-[#25FF00]`}
            >
              <div className="flex flex-col h-full justify-around p-4">
                <div>
                  <h6 className="text-3xl text-green-400">{item.projectName}</h6>
                  
                </div>
                
                <p className="text-base text-slate-300"> {item.projectDesc} </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>

      <div className=" w-1/3 bg-white shadow-default ">
        <div className="border-b border-stroke py-4 px-6 bg-black ">
          <h3 className="font-medium text-xl text-white">Section-4</h3>
        </div>
        <form action="#">
          <div className="p-6">
            
            {projectsData.map((item,index)=>(
              <div key={index} className="flex pt-4 relative flex-wrap border rounded-lg p-2 mb-2 w-full justify-between">
              <div className="mb-1 w-[48%]">
              <label className="mb-2 block text-base font-bold text-gray-800  ">
                Project Name
              </label>
              <input
                type="text"
                value={item.projectName}
                onChange={(e)=>handleChange(e,index)}
                name="projectName"
                placeholder="Enter Name of your Project"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
              />
            </div>
            <div className="mb-1 w-[48%]">
              <label className="mb-2 block text-base font-bold text-gray-800  ">
                Project Link
              </label>
              <input
                type="text"
                value={item.projectLink}
                onChange={(e)=>handleChange(e,index)}
                name="projectLink"
                placeholder="Enter Project Link"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
              />
            </div>
            <div className="mb-4 w-full">
              <label className="mb-2 block text-base font-bold text-gray-800  ">
                Project Description
              </label>
              <textarea
                type="text"
                value={item.projectDesc}
                onChange={(e)=>handleChange(e,index)}
                name="projectDesc"
                placeholder="Enter the Project Description"
                className="w-full rounded border-[1px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter  "
              />
            </div>
            <div className="absolute top-1 right-4 cursor-pointer" onClick={()=>deleteProject(index)}>
                  <MdDeleteForever  className='text-red-700 text-xl'/>
                  </div>
              </div>
            ))}
            <div className="w-full flex justify-end">
            <button
                type="button"
                onClick={addNew}
                className="flex w-fit items-center gap-2 my-3 justify-center rounded bg-blue-400 text-base text-white p-3 font-medium hover:bg-blue-600"
              >
                <MdAdd size={'1.7rem'} />
                Add project
              </button>
              </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateProjects;
