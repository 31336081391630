import React, { useEffect, useState } from "react";
import UpdateHero from "./UpdateHero";
import UpdateAbout from "./UpdateAbout";
import UpdateServices from "./UpdateServices";
import UpdatePortfolio from "./UpdatePortfolio";
import UpdateExperience from "./UpdateExperience";
import UpdateEducation from "./UpdateEducation";
import { GrDocumentUpdate } from "react-icons/gr";
import config from "../../../Config";
import axios from "axios";
import { toast } from "react-toastify";

const Template1Update = () => {
  const token = localStorage.getItem("token");
  const [templateData, setTemplateData] = useState(null);

  const fetchTemplate1 = async () => {
    try {
      const res = await axios.get(
        `${config.BASE_URL}/template1/66bc86540d05df2493711202`
      );
      setTemplateData(res.data);
      console.log(res.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchTemplate1();
  }, []);

  //-------------------------------------------Upload Data------------------------------------------------------------------------->|
  const handleUpload = async () => {
    try {
      const formData = new FormData();

      // Append each file to the formData


      templateData.portfolio.projects.forEach((project,index) => {
        formData.append(`portfolioImages`, project.img);
    });

    formData.append("bgImage",templateData.hero.bgImage);
    

      // Append the rest of templateData as JSON
      formData.append("hero", JSON.stringify(templateData.hero));
      formData.append("about", JSON.stringify(templateData.about));
      formData.append("experience", JSON.stringify(templateData.experience));
      formData.append("education", JSON.stringify(templateData.education));
      formData.append("blogs", JSON.stringify(templateData.blogs));
      formData.append("services", JSON.stringify(templateData.services));
      formData.append("portfolio", JSON.stringify(templateData.portfolio));

      // formData.forEach((value, key) => {
      //   if (value instanceof File) {
      //     console.log(`${key}:`, value.name, value.size, value.type);
      //   } else {
      //     console.log(`${key}:`, value);
      //   }
      // });

      const res = await axios.put(
        `${config.BASE_URL}/template1/66bc86540d05df2493711202`,
        formData, // FormData object as the request body
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file uploads
            "x-auth-token": `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        fetchTemplate1();
        toast.success("Data Updated!");
      } else {
        toast.error("Token Expired, Login again!");
      }
    } catch (e) {
      toast.error(e.message);
      console.log(e.message);
    }
  };

  //-----------------------------------------------------------------------Hero Section-------------------------------------------------------->|
  const handleHeroChange = (e) => {
    const { name, value } = e.target;
    if (name === "roles") {
      const array = value
        .split(",")
        .map((item) => item.trim()) // Trim any whitespace
        .filter((item) => item);
      setTemplateData((prevData) => ({
        ...prevData,
        hero: {
          ...prevData.hero,
          [name]: array,
        },
      }));
    } else if (name === "bgImage" && e.target.files) {
      try {
        const file = e.target.files[0];
        setTemplateData((prevdata) => ({
          ...prevdata,
          hero: { ...prevdata.hero, [e.target.name]: file },
        }));
      } catch (e) {
        console.log(e.message);
      }
    } else {
      setTemplateData((prevData) => ({
        ...prevData,
        hero: {
          ...prevData.hero,
          [name]: value,
        },
      }));
    }
  };

  //---------------------------------------------------------------------About Section---------------------------------------------------------->|
  const handleAboutChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "aboutHead" || name === "aboutDesc") {
      setTemplateData((prevdata) => ({
        ...prevdata,
        about: {
          ...prevdata.about,
          [name]: value,
        },
      }));
    } else if (name === "skill" || name === "level") {
      const updatedSkills = [...templateData.about.skills];
      updatedSkills[index] = {
        ...updatedSkills[index],
        [name]: value,
      };
      setTemplateData((prevdata) => ({
        ...prevdata,
        about: {
          ...prevdata.about,
          skills: updatedSkills,
        },
      }));
    }
  };
  const addNewSkill = () => {
    setTemplateData((prevdata) => ({
      ...prevdata,
      about: {
        ...prevdata.about,
        skills: [
          ...prevdata.about.skills,
          { skill: "", level: 0 }, // Adding a new skill entry with empty values
        ],
      },
    }));
  };

  const deleteSkill = (index) => {
    setTemplateData((prevdata) => ({
      ...prevdata,
      about: {
        ...prevdata.about,
        skills: prevdata.about.skills.filter((_, idx) => idx !== index),
      },
    }));
  };

  //--------------------------------------------------------------------------------Services Section----------------------------------------------------------->|
  const handleServiceChange = (e, index) => {
    if (e.target.name === "serviceHead") {
      setTemplateData((prevData) => ({
        ...prevData,
        services: {
          ...prevData.services,
          [e.target.name]: e.target.value,
        },
      }));
    } else if (e.target.name === "title" || e.target.name === "desc") {
      const updatedData = [...templateData.services.services];
      updatedData[index] = {
        ...updatedData[index],
        [e.target.name]: e.target.value,
      };
      setTemplateData((prevData) => ({
        ...prevData,
        services: {
          ...prevData.services,
          services: updatedData,
        },
      }));
    }
  };

  const addNewService = () => {
    setTemplateData((prevdata) => ({
      ...prevdata,
      services: {
        ...prevdata.services.services,
        services: [
          ...prevdata.services.services,
          { title: "", desc: "" }, // Adding a new skill entry with empty values
        ],
      },
    }));
  };

  const deleteService = (index) => {
    setTemplateData((prevdata) => {
      // Create a copy of the previous state
      const updatedServices = [...prevdata.services.services];
      // Remove the service entry at the specified index
      updatedServices.splice(index, 1);
      // Return the updated state object
      return {
        ...prevdata,
        services: {
          ...prevdata.services,
          services: updatedServices,
        },
      };
    });
  };

  //----------------------------------------------------------------Experience Section--------------------------------------------------->|
  const handleExperienceChange = (event, index) => {
    const { name, value } = event.target;

    // Create a copy of the current state
    const updatedExperience = [...templateData.experience];

    // Update the specific item in the array based on index
    updatedExperience[index] = {
      ...updatedExperience[index],
      [name]: value,
    };

    // Update the state with the modified array
    setTemplateData((prevData) => ({
      ...prevData,
      experience: updatedExperience,
    }));
  };

  const addExperience = () => {
    setTemplateData((prevData) => ({
      ...prevData,
      experience: [
        {
          company: " ",
          startYear: 2024,
          endYear: 0,
          role: " ",
          desc: " ",
        },
        ...prevData.experience,
      ],
    }));
  };

  const deleteExperience = (index) => {
    setTemplateData((prevData) => {
      // Create a copy of the previous state's experience array
      const updatedExperience = [...prevData.experience];
      // Remove the experience entry at the specified index
      updatedExperience.splice(index, 1);
      // Return the updated state object
      return {
        ...prevData,
        experience: updatedExperience,
      };
    });
  };

  //---------------------------------------------------------------------------------Education Section----------------------------------------------->|
  const handleEducationChange = (event, index) => {
    const { name, value } = event.target;

    // Create a copy of the current state
    const updatedEducation = [...templateData.education];

    // Update the specific item in the array based on index
    updatedEducation[index] = {
      ...updatedEducation[index],
      [name]: value,
    };

    // Update the state with the modified array
    setTemplateData((prevData) => ({
      ...prevData,
      education: updatedEducation,
    }));
  };

  const addEducation = () => {
    setTemplateData((prevData) => ({
      ...prevData,
      education: [
        {
          course: " ",
          startYear: 2024,
          endYear: 0,
          institute: " ",
          desc: " ",
        },
        ...prevData.education,
      ],
    }));
  };

  const deleteEducation = (index) => {
    setTemplateData((prevData) => {
      // Create a copy of the previous state's education array
      const updatedEducation = [...prevData.education];
      // Remove the education entry at the specified index
      updatedEducation.splice(index, 1);
      // Return the updated state object
      return {
        ...prevData,
        education: updatedEducation,
      };
    });
  };

  //------------------------------------------------------------------------------Portfolio Section-------------------------------------------------->|
  const handlePortfolioChange = (e, index) => {
    const { name, value, files } = e.target;
    if (name === "img" && files) {
      const file = files ? files[0] : null;
      const imgURL = URL.createObjectURL(file);
      console.log(imgURL);
      const updatedProject = [...templateData.portfolio.projects];
      updatedProject[index] = {
        ...updatedProject[index],
        [name]: file,
      };
      setTemplateData((prevdata) => ({
        ...prevdata,
        portfolio: {
          ...prevdata.portfolio,
          projects: updatedProject,
        },
      }));
    } else if (name === "portfolioHead") {
      setTemplateData((prevdata) => ({
        ...prevdata,
        portfolio: {
          ...prevdata.portfolio,
          [name]: value,
        },
      }));
    } else {
      const updatedProject = [...templateData.portfolio.projects];
      updatedProject[index] = {
        ...updatedProject[index],
        [name]: value,
      };
      setTemplateData((prevdata) => ({
        ...prevdata,
        portfolio: {
          ...prevdata.portfolio,
          projects: updatedProject,
        },
      }));
    }
  };
  const addNewProject = () => {
    setTemplateData((prevdata) => ({
      ...prevdata,
      portfolio: {
        ...prevdata.portfolio,
        projects: [
          ...prevdata.portfolio.projects,
          { projectName: "", projectLink: "", img: null }, // Adding a new skill entry with empty values
        ],
      },
    }));
  };

  const deleteProject = (index) => {
    setTemplateData((prevdata) => {
      // Create a copy of the previous state's projects array
      const updatedProjects = [...prevdata.portfolio.projects];
      // Remove the project entry at the specified index
      updatedProjects.splice(index, 1);
      // Return the updated state object
      return {
        ...prevdata,
        portfolio: {
          ...prevdata.portfolio,
          projects: updatedProjects,
        },
      };
    });
  };

  return (
    <>
      {templateData && (
        <>
          <UpdateHero
            data={templateData.hero}
            handleChange={handleHeroChange}
          />
          <UpdateAbout
            aboutData={templateData.about}
            handleChange={handleAboutChange}
            addNewSkill={addNewSkill}
            deleteSkill={deleteSkill}
          />
          <UpdateServices
            serviceData={templateData.services}
            handleChange={handleServiceChange}
            addNewService={addNewService}
            deleteService={deleteService}
          />
          <UpdatePortfolio
            portfolioData={templateData.portfolio}
            handleChange={handlePortfolioChange}
            addNewProject={addNewProject}
            deleteProject={deleteProject}
          />
          <UpdateExperience
            xpData={templateData.experience}
            handleChange={handleExperienceChange}
            addXp={addExperience}
            deleteExperience={deleteExperience}
          />
          <UpdateEducation
            eduData={templateData.education}
            handleChange={handleEducationChange}
            addEdu={addEducation}
            deleteEducation={deleteEducation}
          />
          <div className="w-full flex justify-end p-5 bg-gray-950">
            <button
              type="button"
              onClick={handleUpload}
              className="flex w-fit gap-2 items-center mx-10 px-12 justify-center  rounded bg-lime-500 text-base text-white p-3 font-medium hover:bg-lime-700"
            >
              <GrDocumentUpdate size={"1.3rem"} /> Update
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Template1Update;
