import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { PiInstagramLogoFill } from "react-icons/pi";
import { RiFacebookBoxFill } from "react-icons/ri";
import { SiLinkedin } from "react-icons/si";
import { Link } from "react-router-dom";
import config from "../../../Config";

const Footer = () => {
  const [data, setData] = useState(null);
  const fetchFooterData = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/footer`);
      setData(res.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchFooterData();
  }, []);

  return (
    <footer className="bg-black text-white opacity-90 items-center  flex justify-center gap-10 p-7">
      {data && (
        <>
          <Link
            to={data.linkedin}
            target="_blank"
            className="hover:text-[#25FF00]"
          >
            {" "}
            <SiLinkedin size={"2rem"} />{" "}
          </Link>
          <Link
            to={data.instagram}
            target="_blank"
            className="hover:text-[#25FF00]"
          >
            {" "}
            <PiInstagramLogoFill size={"2rem"} />{" "}
          </Link>
          <Link
            to={data.twitter}
            target="_blank"
            className="hover:text-[#25FF00]"
          >
            {" "}
            <FaSquareXTwitter size={"2rem"} />{" "}
          </Link>
          <Link to={data.mail} target="_blank" className="hover:text-[#25FF00]">
            {" "}
            <IoIosMail size={"2rem"} />{" "}
          </Link>
          <Link
            to={data.facebook}
            target="_blank"
            className="hover:text-[#25FF00]"
          >
            {" "}
            <RiFacebookBoxFill size={"2rem"} />{" "}
          </Link>
        </>
      )}
    </footer>
  );
};

export default Footer;
