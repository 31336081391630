import React, { useEffect, useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
import { Link } from 'react-scroll'


const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [open,setOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 30) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav>
         <div className=' sm:hidden z-40 fixed top-6 left-4 text-white' onClick={()=>setOpen(!open)}>
      <RxHamburgerMenu size={'1.5rem'}/>
      </div>
      <div className={`w-screen h-screen flex justify-center  bg-black text-white  ${open ? 'left-0 fixed top-0 transition-all duration-700 delay-300' : ' absolute -left-[200%] transition-all duration-700 delay-300'} z-30`}>
      <ul className='list-none flex flex-col gap-10 mt-28 cursor-pointer text-3xl'>
                <li><Link to='about' spy={true} offset={-100} onClick={()=>setOpen(!open)} >About</Link></li>

                <li><Link to='services' spy={true} offset={-100} onClick={()=>setOpen(!open)} >Services</Link></li>

                <li><Link to='portfolio' spy={true} offset={-100} onClick={()=>setOpen(!open)} >Portfolio</Link></li>

                <li><Link to='experience' spy={true} offset={-100} onClick={()=>setOpen(!open)} >Experience</Link></li>

                <li><Link to='blogs' spy={true} offset={-100} onClick={()=>setOpen(!open)} >Blogs</Link></li>
                
                <li><Link to='contact' spy={true} offset={-200} onClick={()=>setOpen(!open)} >Contact</Link></li>
            </ul>
      </div>
<div className={` top-10 fixed w-full hidden sm:flex justify-center z-10`}>
      <div className={`flex  py-4  ${ isSticky ? "w-[40%] rounded-full bg-black backdrop-blur-sm" : " w-[60%] bg-transparent"} transition-all duration-500  bg-opacity-80  justify-center`}>
      <div className='flex justify-around px-4 w-full text-slate-300 text-lg'>
      <Link to='hero' spy={true} smooth={true}  offset={-20} className=' hover:cursor-pointer' activeClass='text-[#25FF00] ' >Home</Link>
        <Link to='about' spy={true} smooth={true} offset={-150}  className=' hover:cursor-pointer' activeClass='text-[#25FF00] ' >About</Link>
        <Link to='experience' spy={true} smooth={true} offset={-100}  className=' hover:cursor-pointer' activeClass='text-[#25FF00] ' >Experience</Link>
        <Link to='projects' spy={true} smooth={true} offset={-100}  className=' hover:cursor-pointer' activeClass='text-[#25FF00] ' >Projects</Link>
        <Link to='blogs' spy={true} smooth={true} offset={-100}  className=' hover:cursor-pointer' activeClass='text-[#25FF00] ' >Blogs</Link>
        <Link to='contact' spy={true} smooth={true} offset={-200}  className=' hover:cursor-pointer' activeClass='text-[#25FF00] ' >Contact</Link>
      </div>
      </div>
      </div>
    </nav>
  )
}

export default Navbar